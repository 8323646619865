@tailwind base;
@tailwind components;
@tailwind utilities;

#sortbox:checked ~ #sortboxmenu {
  opacity: 1;
}

#sortbox:checked ~ #triangle-bottomright {
  opacity: 1;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

#triangle-bottomright {
  width: 0px;
  height: 0;
  border-bottom: 20px solid white;
  border-left: 20px solid transparent;
  position: absolute;
  right: 3px;
  top: 44px;
}

@layer utilities {
  .max-w-quarter {
    max-width: 25%;
  }
}

.learnMore,
.learnMoreYellow,
.learnMoreRed {
  border-bottom: 12px solid transparent;
  transition: all 0.5s ease-in;
}
.learnMoreRed:hover {
  border-bottom: 12px solid hsl(7, 99%, 70%);
}
.learnMoreYellow:hover {
  border-bottom: 12px solid hsl(51, 100%, 49%);
}

path {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 400ms;
}
svg:hover > path {
  fill: #fff;
  cursor: pointer;
}
